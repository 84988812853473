import { getMembership } from "@jog/shared";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMe } from "./useMe";
const MembershipContext = createContext({
    pending: true,
    error: "",
    membership: null,
});
export const MembershipProvider = ({ children }) => {
    const [pending, setPending] = useState(true);
    const [error, setError] = useState("");
    const { user } = useMe();
    const [membership, setMembership] = useState(null);
    const fetch = useCallback(async () => {
        setPending(true);
        setError("");
        await getMembership()
            .then((res) => res.body.membership)
            .then((membership) => setMembership(membership || null))
            .catch((e) => {
            setError(e.message);
        })
            .finally(() => {
            setPending(false);
        });
    }, []);
    useEffect(() => {
        if (user) {
            fetch();
        }
    }, [user, fetch]);
    const value = useMemo(() => ({ pending, error, membership }), [error, membership, pending]);
    return <MembershipContext.Provider value={value}>{children}</MembershipContext.Provider>;
};
export const useMembership = () => useContext(MembershipContext);
