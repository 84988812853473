import { NewsForm } from "@/components";
import { useSocial } from "@/hooks/useSocial";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { ChevronDown, Container, Link, NextImage, useI18n } from "@jog/react-components";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
const Newsletter = ({ className }) => {
    const { $t } = useI18n();
    return (<div className={classNames(className, "flex w-full flex-wrap items-center lg:w-auto lg:flex-nowrap")}>
            <div className="mr-8 hidden font-primary text-22 font-bold uppercase leading-tight text-gray-base lg:block">
                {$t("jc nieuwsbrief")}
            </div>
            <div className="block font-primary text-gray-base lg:hidden">
                <h3 className="font-medium uppercase leading-tight">{$t("Keep in touch")}</h3>
                <p className="mt-1 leading-tight">{$t("Sign up for our newsletter now and receive a discount!")}</p>
            </div>
            <NewsForm source="footer" className="mt-2 lg:mt-0"/>
        </div>);
};
const Social = ({ className }) => {
    const socials = useSocial();
    const { $t } = useI18n();
    return (<div className={classNames(className)}>
            <div className="mb-4 block lg:hidden">
                <h4 className="text-sm font-medium uppercase leading-tight text-gray-base">{$t("More inspiration")}</h4>
            </div>
            <ul className="-mb-1 -mr-3 flex items-center lg:mb-0 lg:h-full">
                {socials.map((item, index) => (<li key={index} className="mb-1 mr-3 lg:mb-0">
                        <Link href={item.href} title="" target="_blank" rel="noopener" className="decoration-0">
                            <NextImage blur={false} src={item.image} alt="Social network icon" width={41} height={35}/>
                        </Link>
                    </li>))}
            </ul>
        </div>);
};
const Nav = ({ nav }) => {
    const [fold, setFold] = useState(true);
    return (<AnimatePresence mode="sync">
            <div>
                <h4 className="flex w-full items-center justify-between font-medium capitalize text-gray-base lg:text-22 lg:font-bold">
                    <Link className="text-gray-base hover:text-orange-main" href={nav.url}>
                        {nav.title}
                    </Link>
                    <div className="flex h-6 items-center pl-8 lg:hidden" onClick={() => setFold((v) => !v)}>
                        <motion.span transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} animate={fold ? "close" : "open"} variants={{ open: { rotate: "180deg" }, close: { rotate: "0deg" } }}>
                            <ChevronDown strokeWidth={2}/>
                        </motion.span>
                    </div>
                </h4>
                <motion.ul transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} animate={fold ? "close" : "open"} variants={{ open: { height: "auto" }, close: { height: 0 } }} className="overflow-hidden lg:!h-auto">
                    {nav.items.map((item, index) => (<li className="py-2" key={index}>
                            <Link className="text-base text-gray-base hover:text-orange-main" href={item.url}>
                                {item.text}
                            </Link>
                        </li>))}
                </motion.ul>
            </div>
        </AnimatePresence>);
};
const Navs = ({ className }) => {
    var _a;
    const layout = useLayout();
    const footer = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.footer;
    if (!footer)
        return <div className="w-full bg-white py-10"/>;
    return (<div className={classNames(className, "grid w-full grid-cols-1 gap-y-3 lg:grid-cols-4")}>
            {footer.columns.map((nav, index) => (<Nav key={index} nav={nav}/>))}
        </div>);
};
const Payments = ({ className }) => {
    var _a, _b;
    const { layout } = useLayout();
    const logos = (_b = (_a = layout === null || layout === void 0 ? void 0 : layout.footer) === null || _a === void 0 ? void 0 : _a.logos) === null || _b === void 0 ? void 0 : _b.flat();
    const { $t } = useI18n();
    if (!logos)
        return <></>;
    return (<div className={className}>
            <div className="mb-4 block lg:hidden">
                <h4 className="text-sm font-medium uppercase leading-tight text-gray-base">{$t("payment options")}</h4>
            </div>
            <ul className="-mb-4 -mr-4.5 flex flex-wrap items-center">
                {logos.map((logo, index) => (<li key={index} className="mb-4 mr-4.5">
                        <NextImage blur={false} src={logo.src} alt="Footer logo" width={(logo.width / logo.height) * 35} height={35}/>
                    </li>))}
            </ul>
        </div>);
};
const Legals = ({ className }) => {
    var _a, _b;
    const { layout } = useLayout();
    const legals = (_b = (_a = layout === null || layout === void 0 ? void 0 : layout.footer) === null || _a === void 0 ? void 0 : _a.legal) === null || _b === void 0 ? void 0 : _b.flat();
    if (!legals)
        return <></>;
    return (<div className={className}>
            <ul className="-mb-2 -mr-7 flex flex-wrap">
                {legals.map((legal, index) => (<li className="mb-2 mr-7" key={index}>
                        <Link className="block whitespace-nowrap text-base text-gray-base hover:text-orange-main" href={legal.url}>
                            {legal.label}
                        </Link>
                    </li>))}
            </ul>
        </div>);
};
const Hr = ({ className }) => (<hr className={classNames(className, "my-8 w-full border-t border-gray-silver lg:my-10")}/>);
export const Footer = ({ newsletter }) => {
    var _a;
    const layout = useLayout();
    const footer = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.footer;
    if (!footer)
        return <div className="w-full bg-white py-10"/>;
    return (<div className="mt-3 w-full bg-white py-10 px-4 lg:px-0">
            <Container className="overflow-hidden">
                <div className="flex flex-wrap justify-between">
                    {newsletter && (<>
                            <Social className="order-8 lg:order-1"/>
                            <Newsletter className="order-2"/>
                            <Hr className="order-3"/>
                        </>)}
                    <Navs className="order-4"/>
                    <Hr className="order-5"/>
                    <Payments className="order-6 w-full lg:w-auto"/>
                    {newsletter && <Hr className="order-7 lg:hidden"/>}
                    <Hr className="order-9 lg:hidden"/>
                    <Legals className="order-10 w-full overflow-hidden lg:w-auto"/>
                </div>
            </Container>
        </div>);
};
