import { Link, RichTextBase, cn } from "@jog/react-components";
import { replacePlaceholderInUrl } from "@jog/shared";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
export const JCRichText = RichTextBase({ paragraphDefaultCss: "text-base" });
const sizes = {
    xs: ["text-base", "text-sm", "text-xs"],
    sm: ["text-lg", "text-base", "text-sm"],
};
export const RichText = ({ field, replace, size, }) => {
    const font = sizes[size || "xs"] || sizes.xs;
    return (<PrismicRichText field={field} components={{
            heading1: ({ children }) => (<h1 className={cn("mb-3 leading-tight tracking-0.03 text-grey-default", font[0])}>{children}</h1>),
            heading2: ({ children }) => (<h2 className={cn("mb-2 leading-tight tracking-0.03 text-grey-default", font[0])}>{children}</h2>),
            heading3: ({ children }) => (<h3 className={cn("mb-1 leading-tight tracking-0.03 text-grey-default", font[0])}>{children}</h3>),
            heading4: ({ children }) => (<h4 className={cn("mb-0.5 leading-tight tracking-0.03 text-grey-default", font[0])}>{children}</h4>),
            heading5: ({ children }) => (<h5 className={cn("mb-0.5 leading-tight tracking-0.03 text-grey-default", font[1])}>{children}</h5>),
            heading6: ({ children }) => (<h6 className={cn("mb-0.5 leading-tight tracking-0.03 text-grey-default", font[1])}>{children}</h6>),
            span: ({ text, key }) => {
                const result = [];
                const _text = replace ? text.replace(/{([^}]+)}/g, (ori, k) => replace[k] || ori) : text;
                let i = 0;
                for (const line of _text.split("\n")) {
                    if (i > 0) {
                        result.push(<br key={`${i}__break`}/>);
                    }
                    result.push(<React.Fragment key={`${i}__line`}>{line}</React.Fragment>);
                    i++;
                }
                return <React.Fragment key={key}>{result}</React.Fragment>;
            },
            paragraph: (props) => {
                const { children, node, text } = props;
                if (!node.spans.length && !text)
                    return (<p className={cn("leading-tight tracking-0.03 text-grey-default", font[2])}>
                                <br />
                            </p>);
                return <p className={cn("leading-tight tracking-0.03 text-grey-default", font[2])}>{children}</p>;
            },
            hyperlink: ({ children, node }) => {
                if (node.data.url) {
                    node.data.url = replacePlaceholderInUrl(node.data.url).replace(process.env.NEXT_PUBLIC_SITE_URL || "", "");
                    node.data.url = node.data.url.replace(/https:\/\/tel*:/, "tel:");
                    node.data.url = node.data.url.replace(/https:\/\/mailto:/, "mailto:");
                }
                const target = node.data.link_type === "Web" && node.data.target ? node.data.target : "_self";
                return (<Link href={node.data.url} className="underline" target={target}>
                            {children}
                        </Link>);
            },
            list: ({ children }) => (<ul className="ml-5 leading-tight tracking-0.03 text-grey-default">{children}</ul>),
            listItem: ({ children }) => (<li className="mb-4 list-disc leading-tight tracking-0.03 text-grey-default last-of-type:mb-0">
                        {children}
                    </li>),
        }}/>);
};
