// TODO delete this file: react-bootstrap  10px = 10px
export const theme = {
    color: {
        // ===================== Tailwind Css
        mainGreen: "#809A7B", // green-main
        iconStroke: "#5b606b", // gray-stroke
        iconLabel: "#484b51", // gray-label
        lightGreen: "#e3f0bf", // green-light
        draculaGray: "#212529", // gray-dracula
        mainGray: "#444a55", // gray-base
        shuttleGray: "#5C616B", // gray-main
        darkGray: "#737780", // gray-dark
        gray: "#a1a4aa", // gray-main
        silverGray: "#bfbfbf", // gray-silver
        mediumGray: "#d0d2d4", // gray-medium
        lightGray: "#eeeeee", // gray-light
        backgroundGray: "#f7f7f7", // gray-bg
        monzaRed: "#E30613", // red-mona
        mainRed: "#ff0000", // red-main
        errorRed: "#a54445", // red-error
        pink: "#ea5175", // pink-main
        salmonPink: "#f1dede", // pink-salmon
        darkBlue: "#153e6e", // blue-dark
        taraBlue: "#074054", // blue-tara
        whiteBlue: "#2196f3", // blue-main
        yellow: "#ffcf0c", // yellow-main
        lightYellow: "#fcf8e3", // yellow-light
        mcKenzie: "#8a6d3b", // yellow-mcKenzie
        primaryHover: "#ee582a", // orange-main
        lightOrange: "#ef9e77", // orange-light
        alertBoxGray: "#434955", // gray-alertBox
        yellowGreen: "#bed776", // green-yellow
        pastelOlive: "#f4f8ea", // pastelOlive
        anthracite: "#4e4e4e", // anthracite
        black: "#000000", // white
        white: "#ffffff", // black
    },
    zIndex: {
        fixed: 7,
        headerWrapper: 2,
        navWrapper: 7,
        snackbar: 3,
        dropDownBox: 4,
        modal: 9,
        logoLoader: 10,
        brandLogo: 2,
    },
    padding: {
        small: "5px",
        standard: "10px",
        large: "20px",
        // Progressive enhancement - start using these on new code
        // Note: we don't have a Design System yet so this is arbitrary
        pd1: "3px",
        pd2: "5px",
        pd3: "10px",
        pd4: "15px",
        pd5: "20px",
        pd6: "30px",
    },
    spacing: {
        // Note: we don't have a Design System yet so this is arbitrary
        sp1: "3px",
        sp2: "5px",
        sp3: "10px",
        sp4: "15px",
        sp5: "20px",
        sp6: "30px",
    },
    fontFamily: {
        icomoonJC: "icomoon-jc",
        primary: "AvenirNextLTW01",
    },
    fontSize: {
        paragraph: "8px",
        label: "11px",
        itemLabel: "11px", // BUG: This was missing from the JS land, this is a value created to make TSC happy
        mainHeader: "20px",
        secondaryHeader: "9px",
        itemHeader: "15px",
        priceElement: "16px",
        modalParagraph: "14px",
        // Progressive enhancement - start using these on new code
        // Note: we don't have a Design System yet so this is arbitrary
        fs1: "8px",
        fs2: "9px",
        fs3: "11px",
        fs4: "14px",
        fs5: "15px",
        fs6: "16px",
        fs7: "20px",
    },
    // This property is also used in util/responsiveImages.js. The order of the values here is important! sm -> xl
    media: {
        sm: "screen and (max-width: 575px)", //   sm =    0 -  575
        md: "screen and (max-width: 767px)", //   md =  576 -  767
        lg: "screen and (max-width: 991px)", //   lg =  768 -  991
        xl: "screen and (max-width: 1199px)", //   xl =  992 - 1199
        xxl: "screen and (max-width: 1399px)", //  xxl = 1200 - 1399
        // xxxl: 'screen and (max-width: inf); // xxxl = 1400 -  inf - illustrative only, use 'theme.mediaFrom.xxl' to target this size
    },
    // This follows Bootstrap breakpoints
    mediaFrom: {
        // xs: 'screen and (min-width: 0px)'   //  xs =    0 -  575 - illustrative only, use default styles (no media queries) to target this size
        sm: "screen and (min-width: 576px)", //  sm =  576 -  767
        md: "screen and (min-width: 768px)", //  md =  768 -  991
        lg: "screen and (min-width: 992px)", //  lg =  992 - 1199
        xl: "screen and (min-width: 1200px)", //  xl = 1200 - 1399
        xxl: "screen and (min-width: 1400px)", // xxl = 1400 -  inf
    },
};
