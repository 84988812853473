import { DUTCH_WEEK } from "@jog/shared";
import { useI18n } from "../../hooks";
export const StoreWorkTime = ({ openings }) => {
    const { $t } = useI18n();
    return (<div>
            <div>
                <div className="font-bold">{$t("Opening hours")}</div>
                {DUTCH_WEEK.map((i) => ({ day: i, time: openings[i] || "" })).map(({ day, time }) => (<div key={day}>
                        <span className="inline-block w-[50px]">{day}</span>
                        <span>{time}</span>
                    </div>))}
            </div>
            <div dangerouslySetInnerHTML={{ __html: openings.remarks }} className="mt-5"/>
        </div>);
};
