import { RadioTabBase, cn } from "@jog/react-components";
import React from "react";
const RadioTabWrapper = ({ isActive, children }) => (<div className={cn("mb-5 border border-gray-medium p-4 text-base text-gray-base", isActive ? "bg-gray-bg" : "bg-white")}>
        {children}
    </div>);
export const RadioTab = React.memo(RadioTabBase({
    RadioTabWrapper: RadioTabWrapper,
    tabHeaderWrapperCss: {
        ".tab-head__right-wrapper": {
            color: "#809A7B",
            ".location-icon::before": {
                fontSize: "16px",
            },
        },
        ".jc-green": {
            color: "#809A7B",
        },
    },
    distanceLabelColor: "#444a55",
}));
